<template>
  <div class="content">
    <ul class="content__list">
      <li
          class="content__list-item"
          v-show="layoutIndex === shownLayout"
          v-for="(contentData, layoutIndex) in routerData"
          v-bind:key="layoutIndex"
      >
        <div class="writer-tile" :class="{'-active' : activeIds.includes(contentData.id)}">
          <div class="writer-tile__top" :class="{'-active' : activeIds.includes(contentData.id)}">
            <div class="writer-tile__flag" v-if="contentData.id === selectedLayout">
              <Icon name="flag" />
            </div>
            <title-editor
                :value="contentData?.name"
                @edit-title="(value) => { editLayoutName(value, layoutIndex)}"
            />
            <span style="margin-left: auto; padding-left: 10px;">
              ({{layoutIndex + 1}}/{{routerData.length}})
            </span>
            <div class="writer-tile__delete">
              <button class="button edit-button" @click="deleteLayout(layoutIndex)">
                <Icon name="delete-button" css-class="-edit" />
              </button>
            </div>
          </div>
          <div class="writer-tile__body">
            <button class="writer-tile__prev" @click="prevLayout">
              <Icon name="chevron-up" css-class="-layout-prev" />
            </button>
            <button class="writer-tile__next" @click="nextLayout">
              <Icon name="chevron-up" css-class="-layout-next" />
            </button>
            <div
                class="writer-tile__dropzone"
                data-signal-drop
                v-on:drop="onDropSignal($event, layoutIndex)"
                v-on:dragenter.prevent="dragEnterStyle($event)"
                v-on:dragover.prevent="dragEnterStyle($event)"
                v-on:dragleave.prevent="dragLeaveStyle($event)"
            >
              <span
                  class="writer-tile__signal"
                  v-for="(selector, index) in contentData?.selectors"
                  v-bind:key="index"
                  v-bind:class="{'not' : selector.startsWith('not.')}"
              >
                <button
                    v-on:click="toggleSignalInLayout(selector, layoutIndex)"
                >
                  {{selector}}
                </button>
                <button v-on:click="removeSignalFromLayout(selector, layoutIndex)">
                  <Icon name="cancel" />
                </button>
              </span>
            </div>
            <div
                class="writer-tile__content"
                data-section-drop
                v-on:drop="onDropAppendSection($event, contentData.id)"
                v-on:dragenter.prevent="dragEnterStyle($event)"
                v-on:dragover.prevent="dragEnterStyle($event)"
                v-on:dragleave.prevent="dragLeaveStyle($event)"
            >
              <writer-section-list
                  v-bind:section-list-data="sectionList(contentData.id)"
                  v-bind:data-handler="dataHandler"
                  v-bind:layout-id="contentData.id"
                  v-on:drag-sort="(value) => { draggingSortItem = value }"
                  v-bind:dragging-sort-item="draggingSortItem"
                  v-bind:duplicate-sections="duplicateSections"
                  v-bind:layout-active="layoutIndex === shownLayout"
              />
              <div class="content__button">
                <button class="button" @click="createAndAppendNewSection(contentData.id)">
                  <Icon name="add-button" css-class="-edit" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="content__button">
      <button class="button" @click="appendNewLayout">
        <Icon name="add-button" css-class="-add" />
      </button>
    </div>
  </div>
  <Modal
      v-bind:show-modal="deletionModalOpen"
      @toggle-modal="confirmDeletion(false)"
      @submit-click="confirmDeletion(true)"
      @cancel-click="confirmDeletion(false)"
      header-text="Bestätigung"
      button-text="Löschen"
      cancel-button-text="Abbrechen"
      v-bind:centered="true"
  >
    <template v-slot:body>
      <div>
        Sind Sie sicher?
      </div>
    </template>
  </Modal>
  <teleport to="#workspaceSettings" v-if="teleportReady">
    <button
        class="button edit-button -toggle -find"
        v-bind:class="{'-inactive': !selectedLayout}"
        @click="highlightSelected"
    >
      <Icon name="flag" css-class="-view-toggle" />
    </button>
  </teleport>
</template>

<script>
import TitleEditor from "@/components/TitleEditor";
import Icon from "@/components/generic/Icon";
import Modal from "@/components/generic/Modal";
import WriterSectionList from "@/components/writerView/WriterSectionList";
import DragDrop from "@/classes/DragDrop";

export default {
  name: 'writer-widget',
  mixins: [DragDrop],
  components: {
    WriterSectionList,
    TitleEditor,
    Icon,
    Modal,
  },
  props: {
    dataHandler: {
      type: Object,
    },
    routerData: {
      type: Array,
    },
    activeIds: {
      type: Array,
    },
    customerId: {
      type: String,
    },
    activeFilterToggled : {
      type: Boolean,
    },
  },
  data() {
    return {
      shownLayout: 0,
      draggingSortItem: null,
      deletionModalOpen: false,
      deletionIndex: null,
      teleportReady: false,
    };
  },
  computed: {
    workspaceData() {
      return this.dataHandler.getData();
    },
    duplicateSections() {
      let arr = [];
      this.routerData.forEach( item => arr.push(...item.section_ids))
      return arr.filter((item, index) => index !== arr.indexOf(item));
    },
    selectedLayout() {
      return this.dataHandler.getSelectedLayout();
    },
    selectedSection() {
      return this.dataHandler.getSelectedSection();
    },
  },
  methods: {
    highlightSelected() {
      let selectedLayoutId = this.selectedLayout;
      let selectedLayout = this.dataHandler.getLayoutById(selectedLayoutId);
      this.shownLayout = this.routerData.indexOf(selectedLayout);

      const sectionAlternativeObject = this.selectedSection;
      const sectionId = Object.keys(sectionAlternativeObject)[0]
      const sectionAlternativeId = sectionAlternativeObject[sectionId];
      console.log(sectionId);
      console.log(sectionAlternativeId);
    },
    sectionList(layoutId){
      return this.dataHandler?.getSectionsByLayoutId(layoutId);
    },
    createAndAppendNewSection(layoutId) {
      this.dataHandler?.createAndAppendNewSection(layoutId);
    },
    editLayoutName(value, index) {
      this.dataHandler?.editLayoutName(value, index);
    },
    deleteLayout(index) {
      this.deletionIndex = index;
      this.deletionModalOpen = true;
    },
    confirmDeletion(response) {
      if (response) {
        this.dataHandler?.deleteLayout(this.deletionIndex);
      }
      this.deletionIndex = null;
      this.deletionModalOpen = false;
      this.shownLayout = 0;
    },
    toggleSignalInLayout(signal, layoutIndex) {
      this.dataHandler?.toggleSignalInLayout(signal, layoutIndex);
    },
    removeSignalFromLayout(signal, layoutIndex) {
      this.dataHandler?.removeSignalFromLayout(signal, layoutIndex);
    },
    appendNewLayout() {
      this.dataHandler?.appendNewLayout();
      this.shownLayout = this.routerData.length - 1;
    },
    prevLayout() {
      if(this.shownLayout > 0) {
        this.shownLayout -= 1;
      }
    },
    nextLayout() {
      if(this.shownLayout < this.routerData.length - 1) {
        this.shownLayout += 1;
      }
    },
  },
  watch: {
    activeFilterToggled(val) {
      if (val) {
        this.shownLayout = 0;
      }
    },
  },
  mounted() {
    this.teleportReady = true;
  },
  beforeUnmount() {
    this.teleportReady = false;
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/components/_writer-tile.scss";
</style>