<template>
  <div class="writer-section">
    <div
        class="writer-tile -section"
        data-drag-item
        data-section-drop
        v-on:drop="onDropSort($event, sectionData.id)"
        v-on:dragenter.prevent="dragEnterStyle($event)"
        v-on:dragover.prevent="dragEnterStyle($event)"
        v-on:dragleave.prevent="dragLeaveStyle($event)"
    >
      <div class="writer-tile__top -section">
        <div
            class="writer-tile__drag"
            draggable="true"
            v-on:dragstart="dragStartSection($event, sectionData.id)"
        >
          <Icon name="drag" css-class="-drag" />
        </div>
        <title-editor
            v-bind:value="sectionData.name"
            @edit-title="(value) => { editSectionTitle(value)}"
        />
        <div class="writer-tile__delete">
          <button class="button edit-button" @click="deleteLayoutSection">
            <Icon name="delete-button" css-class="-edit" />
          </button>
        </div>
      </div>
      <div
          class="writer-tile__middle"
          v-for="(sectionAlternative, sectionAlternativeIndex) in sectionData.section_alternatives"
          v-show="sectionAlternativeIndex === shownSectionAlternative"
          v-bind:key="sectionAlternativeIndex"
          :class="{'-active' : activeSectionAlternatives[sectionData.id]?.includes(sectionAlternative.id)}"
      >
        <div class="writer-tile__flag" v-if="selectedSectionAlternative(sectionAlternative.id)">
          <Icon name="flag" />
        </div>
        <title-editor
            :value="sectionAlternative.name"
            @edit-title="(value) => { editSectionAlternativeName(sectionAlternative.id, value)}"
        />
        <span style="margin-left: auto; padding-left: 10px;">
          ({{sectionAlternativeIndex + 1}}/{{sectionData.section_alternatives.length}})
        </span>
        <div class="writer-tile__delete">
          <button class="button edit-button" @click="deleteSectionAlternative(sectionAlternative.id)">
            <Icon name="delete-button" css-class="-edit" />
          </button>
        </div>
      </div>
      <div class="writer-tile__body -section">
        <button class="writer-tile__prev" @click="prevSectionAlternative()">
          <Icon name="chevron-up" css-class="-layout-prev" />
        </button>
        <button class="writer-tile__next" @click="nextSectionAlternative(sectionData.section_alternatives)">
          <Icon name="chevron-up" css-class="-layout-next" />
        </button>
        <div
            class="writer-tile__dropzone"
            data-signal-drop
            v-for="(sectionAlternative, sectionAlternativeIndex) in sectionData.section_alternatives"
            v-show="sectionAlternativeIndex === shownSectionAlternative"
            v-bind:key="sectionAlternativeIndex"
            v-on:drop="onDropSignalSectionAlternative($event, sectionData.id, sectionAlternativeIndex)"
            v-on:dragenter.prevent="dragEnterStyle($event)"
            v-on:dragover.prevent="dragEnterStyle($event)"
            v-on:dragleave.prevent="dragLeaveStyle($event)"
        >
              <span
                  class="writer-tile__signal"
                  v-for="(selector, index) in sectionAlternative.selectors"
                  v-bind:key="index"
                  v-bind:class="{'not' : selector.startsWith('not.')}"
              >
                <button
                    v-on:click="toggleSignalInSectionAlternative(selector, sectionData.id, sectionAlternativeIndex)"
                >
                  {{selector}}
                </button>
                <button v-on:click="removeSignalFromSectionAlternative(selector, sectionData.id, sectionAlternativeIndex)">
                  <Icon name="cancel" />
                </button>
              </span>
        </div>
        <div class="writer-tile__content">
          <div
              class="writer-tile -text-edit"
              v-for="(sectionAlternative, sectionAlternativeIndex) in sectionData.section_alternatives"
              v-show="sectionAlternativeIndex === shownSectionAlternative"
              v-bind:key="sectionAlternativeIndex"
          >
            <div class="writer-tile__body -section">
              <button class="writer-tile__prev" @click="prevTextAlternative(sectionAlternative.text_alternatives)">
                <Icon name="chevron-up" css-class="-layout-prev" />
              </button>
              <button class="writer-tile__next" @click="nextTextAlternative(sectionAlternative.text_alternatives)">
                <Icon name="chevron-up" css-class="-layout-next" />
              </button>
              <div class="writer-tile__content -text-edit">
                <div
                    class="writer-tile__text"
                    v-for="(textAlternative, textAlternativeIndex) in sectionAlternative.text_alternatives"
                    v-bind:key="textAlternativeIndex"
                    v-show="textAlternativeIndex === shownTextAlternative"
                >
                  <div v-bind:id="`text-edit-toolbar-${sectionAlternative.id}${textAlternative.id}`" ></div>
                  <writer-tiny-mce  v-if="layoutActive"
                                    v-bind:section-alternative-id="sectionAlternative.id"
                                    v-bind:html-text="textAlternative"
                                    v-bind:signals="signalData"
                                    @change="changeTextAlternative"
                                    @delete="deleteTextAlternative" />
                </div>
                <button class="button -layout" @click="appendTextAlternative(sectionAlternative.id, sectionAlternative.text_alternatives.length)">
                  <Icon name="add-button" v-bind:css-class="'-edit'" />
                </button>
              </div>
            </div>
          </div>
          <div class="content__button">
            <button class="button" @click="appendSectionAlternative(sectionData.section_alternatives)">
              <Icon name="add-button" css-class="-edit" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleEditor from "@/components/TitleEditor";
import WriterTinyMce from "@/components/writerView/WriterTinyMce";
import Icon from "@/components/generic/Icon";
import DragDrop from "@/classes/DragDrop";

export default {
  name: 'writer-section',
  emits:[
    'drag-sort',
    'delete-section',
    'delete-section-alternative',
    'delete-text-alternative',
  ],
  mixins: [DragDrop],
  components: {
    Icon,
    TitleEditor,
    WriterTinyMce,
  },
  data() {
    return {
      shownSectionAlternative: 0,
      shownTextAlternative: 0,
      activeIds: null,
    };
  },
  props: {
    dataHandler: {
      type: Object,
    },
    sectionData: {
      type: Object,
    },
    draggingSortItem: {
      type: Number,
    },
    layoutActive: {
      type: Boolean,
    },
  },
  computed: {
    signalData() {
      return this.dataHandler.getSignals();
    },
    selectedSectionAlternativeId() {
      return this.dataHandler.getSelectedSectionAlternative(this.sectionData.id);
    },
    activeSectionAlternatives() {
      return this.dataHandler.getActiveSectionAlternatives();
    },
  },
  methods: {
    selectedSectionAlternative(sectionAlternativeId) {
      return sectionAlternativeId === this.selectedSectionAlternativeId;
    },
    editSectionTitle(value) {
      this.dataHandler.editSectionTitle(value, this.sectionData.id);
    },
    editSectionAlternativeName(sectionAlternativeId, value) {
      this.dataHandler?.editSectionAlternativeName(this.sectionData.id, sectionAlternativeId, value);
    },
    prevSectionAlternative() {
      if(this.shownSectionAlternative > 0) {
        this.shownSectionAlternative -= 1;
        this.shownTextAlternative = 0;
      }
    },
    nextSectionAlternative(sectionAlternatives) {
      if(this.shownSectionAlternative < sectionAlternatives.length - 1) {
        this.shownSectionAlternative += 1;
        this.shownTextAlternative = 0;
      }
    },
    prevTextAlternative() {
      if(this.shownTextAlternative > 0) {
        this.shownTextAlternative -= 1;
      }
    },
    nextTextAlternative(textAlternatives) {
      if(this.shownTextAlternative < textAlternatives.length - 1) {
        this.shownTextAlternative += 1;
      }
    },
    changeTextAlternative(sectionAlternativeId, textAlternativeId, textAlternativeHTML) {
      this.dataHandler?.changeTextAlternative(this.sectionData.id, sectionAlternativeId, textAlternativeId, textAlternativeHTML);
    },
    appendTextAlternative(sectionAlternativeId, latestItemIdx) {
      this.dataHandler?.appendTextAlternativeToSectionAlternative(this.sectionData.id, sectionAlternativeId);
      this.shownTextAlternative = latestItemIdx;
    },
    appendSectionAlternative(sectionAlternatives) {
      this.dataHandler?.appendSectionAlternativeToSection(this.sectionData.id);
      this.shownSectionAlternative = sectionAlternatives.length - 1;
    },
    toggleSignalInSectionAlternative(signal, sectionId, sectionAlternativeIndex) {
      this.dataHandler?.toggleSignalInSectionAlternative(signal, sectionId, sectionAlternativeIndex);
    },
    removeSignalFromSectionAlternative(signal, sectionId, sectionAlternativeIndex) {
      this.dataHandler?.removeSignalFromSectionAlternative(signal, sectionId, sectionAlternativeIndex);
    },
    deleteLayoutSection() {
      this.$emit('delete-section', this.sectionData.id);
    },
    deleteSectionAlternative(sectionAlternativeId) {
      if (this.sectionData.section_alternatives.length > 1) {
        this.$emit('delete-section-alternative',this.sectionData.id, sectionAlternativeId);
        this.shownSectionAlternative = 0;
      }
    },
    deleteTextAlternative(sectionAlternativeId, textAlternativeId) {
      this.$emit('delete-text-alternative', this.sectionData.id, sectionAlternativeId, textAlternativeId);
      this.shownTextAlternative = 0;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/components/_writer-tile.scss";

.writer-section {
  margin-bottom: rem(20px);
}


</style>