<template>
  <div class="writer-section-list">
    <writer-section
        v-for="(data, index) in sectionListData"
        v-bind:key="index"
        v-bind:section-data="data"
        v-bind:data-handler="dataHandler"
        v-on:drag-sort="(value) => { this.draggingItem = value }"
        v-bind:dragging-sort-item="this.draggingItem"
        v-bind:layout-active="layoutActive"
        @delete-section="deleteLayoutSection"
        @delete-section-alternative="deleteSectionAlternative"
        @delete-text-alternative="deleteTextAlternative"
    />
  </div>
  <Modal
      v-bind:show-modal="deletionModalOpen"
      @toggle-modal="confirmDeletion(false)"
      @submit-click="confirmDeletion(true)"
      @cancel-click="confirmDeletion(false)"
      header-text="Bestätigung"
      button-text="Löschen"
      cancel-button-text="Abbrechen"
      v-bind:centered="true"
  >
    <template v-slot:body>
      <div>
        Sind Sie sicher?
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import WriterSection from "@/components/writerView/WriterSection";

export default {
  name: 'writer-section-list',
  emits:['drag-sort'],
  components: {
    Modal,
    WriterSection,
  },
  data() {
    return {
      deletionModalOpen: false,
      deletionId: null,
      draggingItem: this.draggingSortItem,
    };
  },
  props: {
    dataHandler: {
      type: Object,
    },
    sectionListData: {
      type: Array,
    },
    draggingSortItem: {
      type: Number,
    },
    layoutId: {
      type: Number,
    },
    layoutActive: {
      type: Boolean,
    },
    duplicateSections: {
      type: Array,
    },
  },
  computed: {
  },
  methods: {
    deleteLayoutSection(sectionId) {
      if (this.duplicateSections.indexOf(sectionId) !== -1) {
        this.dataHandler?.deleteLayoutSection(sectionId, this.layoutId);
      } else {
        this.deletionId = sectionId;
        this.deletionModalOpen = true;
      }
    },
    deleteSectionAlternative(sectionId, sectionAlternativeId) {
      this.dataHandler?.deleteSectionAlternative(sectionId, sectionAlternativeId);
    },
    deleteTextAlternative(sectionId, sectionAlternativeId, textAlternativeId) {
      this.dataHandler?.deleteTextAlternative(sectionId, sectionAlternativeId, textAlternativeId);
    },
    confirmDeletion(response) {
      if (response) {
        this.dataHandler?.deleteLayoutSection(this.deletionId, this.layoutId);
        this.dataHandler?.deleteSection(this.deletionId);
      }
      this.deletionId = null;
      this.deletionModalOpen = false;
    },
  },
}
</script>