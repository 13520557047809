<template>
  <div
      @click.stop='init'
      v-bind:class='`text-edit-tiny-mce text-edit-tiny-mce-${sectionAlternativeId}${htmlText.id}`'
      v-bind:id='`text-edit-tiny-mce-${sectionAlternativeId}${htmlText.id}`'
      v-html='htmlText.text'>
  </div>
</template>
<script>
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default/icons';
import 'tinymce/plugins/noneditable/';
import 'tinymce/plugins/lists/';

export default {
  name: 'WriterTinyMce',
  emits:['delete', 'change'],
  props: {
    htmlText: {
      type: Object,
    },
    sectionAlternativeId: {
      type: Number,
    },
    signals: {
      type: Array,
    },
  },
  data() {
    return {
      content: this.htmlText.text,
      filteredSignals: [],
      setCursorToEnd: false,
      selfDestruct: false,
    }
  },
  methods: {
    init() {
      this.selfDestruct = false;
      this.autocompleteList(this.signals);
      this.initRTE();
    },
    destroy() {
      this.filteredSignals = [];
      tinymce.remove(`#text-edit-tiny-mce-${this.sectionAlternativeId}${this.htmlText.id}`);
    },
    autocompleteList(array) {
      if (array && array.length > 0) {
        array.forEach(signal => {
          if (signal.children.length > 0) {
            this.autocompleteList(signal.children);
          }
          this.filteredSignals.push({text: signal.id, value: signal.id});
        })
      }
    },
    initRTE() {
      tinymce.init({
        id: `#text-edit-tiny-mce-${this.sectionAlternativeId}${this.htmlText.id}`,
        selector: `#text-edit-tiny-mce-${this.sectionAlternativeId}${this.htmlText.id}`,
        inline: true,
        valid_elements : ''
            +'h1,'
            +'h2,'
            +'h3,'
            +'p,'
            +'strong,'
            +'em,'
            +'span[class|align],'
            +'li,'
            +'ol[align],'
            +'ul[align],'
            +'br,'
        ,
        /*fixed_toolbar_container: `#text-edit-toolbar-${this.sectionAlternativeId}${this.htmlText.id}`,*/
        menubar: false,
        resize: false,
        toolbar: 'undo redo | h1 h2 h3 | bold italic | removeformat | bullist numlist | aligncenter alignjustify alignleft alignright alignnone| deleteField',
        font_css: 'tiny-mce-styles.css',
        plugins: 'noneditable lists',
        noneditable_noneditable_class: 'signal',
        content_style: '.signal { color: #69A0D3; }',
        setup: (editor) => {
          this.editor = editor;
          setTimeout(() => {
            editor?.selection.select(editor.getBody(), true);
            editor?.selection.collapse(false);
          }, 200);
          /**
           * fired when autocomplete item is selected.
           * inserts selected value wrapped in signal-span
           * @param autocompleteApi
           * @param rng
           * @param value
           */
          const onAutoCompleteAction = (autocompleteApi, rng, value) => {
            editor.selection.setRng(rng);
            editor.insertContent(`<span class='signal'>${value}</span>`);
            autocompleteApi.hide();
          };

          /**
           * get matched autocomplete search.
           * sorts filtered signal list bei length.
           * filters inserted matches in uppercase.
           * @param pattern
           * @returns {*[]}
           */
          const getMatchedChars = (pattern) => {
            if(this.filteredSignals.length) {
              return this.filteredSignals.sort((a, b) => {
                // sort alphabetically
                if(a.value < b.value) { return -1; }
                if(a.value > b.value) { return 1; }
                return 0;
              }).sort((one, other) => {
                //sort by signal length
                return one.value.split('.').length - other.value.split('.').length;
              }).filter((char) => {
                //compare with uppercase input
                return char.text.indexOf(pattern.toUpperCase()) !== -1;
              });
            }
            return [];
          };

          /**
           * An autocompleter that allows you to insert signals
           */
          editor.ui.registry.addAutocompleter('autocomplete', {
            ch: '@',
            minChars: 0,
            columns: 1,
            onAction: onAutoCompleteAction,
            fetch: function (pattern) {
              return new tinymce.util.Promise( (resolve) => {
                const list = getMatchedChars(pattern);
                let results = [];
                if(list.length) {
                  //return result
                  results = list.map((char) => {
                    return { type: 'cardcontainer', value: char.value, text: char.text,}
                  });
                } else {
                  // return error message
                  results = [{ type: 'cardcontainer', value: '', text: 'no signals found',}];
                }
                resolve(results);
              });
            }
          });

          /**
           * register delete-button
           */
          editor.ui.registry.addButton('deleteField', {
            icon: 'remove',
            onAction: () => {
              this.$emit('delete', this.sectionAlternativeId, this.htmlText.id);
            },
          });

          /**
           * on blur actions
           */
          editor.on('blur', () => {
            this.content = editor.getContent();
            if (this.content !== this.htmlText.text) {
              this.$emit('change', this.sectionAlternativeId, this.htmlText.id, this.content);
            }
            this.selfDestruct = true;
          });

          /**
           * on focus actions
           */
          editor.on('focusin', (event) => {
            /**
             * check if the focus-event was triggerd by click ob a-tag in example text or click by user.
             * event.relatedTarget.tagName will return 'A' when clicked on link in example text
             * else it will be 'DIV' since the editor is a div
             */
            if(event.relatedTarget?.tagName === 'A') {
              /**
               * we need a small delay to wait for the body to load and set the cursor to end
               */
              setTimeout(() => {
                editor?.selection.select(editor.getBody(), true);
                editor?.selection.collapse(false);
              }, 200);
            }
          });
        }
      });
    },
  },
  watch: {
    selfDestruct(val) {
      if (val) {
        this.destroy()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tox-tinymce-inline {
  z-index: 1000;
}
.text-edit-tiny-mce {
  width: 100%;
  max-height: rem(300px);
  padding: rem(15px);
  font-size: rem(14px);
  overflow: auto;
  outline: none;
}
</style>